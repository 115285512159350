import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import HeartQuote from '../components/HeartQuote';
import YouTube from '../components/YouTube';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Overwatch Hero Picker with CSS",
  "date": "2017-07-18",
  "promo": "grids",
  "description": "Recreate the awesome Overwatch Hero UI with CSS Grid, Flexbox, and other web goodies.",
  "color": "#955cdb"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Let's build the awesome Hero Picker UI from Overwatch. We'll use CSS grids, flexbox, and a bunch of other goodies. You'll want to grab `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/channel/desktop/"
      }}>{`Firefox Nightly`}</a>{` for its awesome Grid layout tool.`}</p>
    <p>{`Clone `}<a parentName="p" {...{
        "href": "https://github.com/geddski/overwatch-hero-picker"
      }}>{`the repo`}</a>{` and let's build it together.`}</p>
    <pre><code parentName="pre" {...{}}>{`git clone https://github.com/geddski/overwatch-hero-picker.git
`}</code></pre>
    <YouTube id="ccRyCOI0-7Q" mdxType="YouTube" />
    <p>{`Let me know if you find other awesome UI's (from games/sci-fi movies/etc.) you'd like walkthroughs for!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      